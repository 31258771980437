import React from "react"
import { Helmet } from "react-helmet"
import { getEnv, isBrowser, loadScript, getQueryParameter } from '../utils/common.js'
import EvergageScript from './evergage.js'
import { withPrefix, withAssetPrefix } from "gatsby"
import { unescapeAsciiCharacters } from '../utils/unescapeAsciiCharacters';
let getCanonicalUrl = (
  canonical,
  slug
) => {
  let baseURL = ''
  let urlParams = ''
  isBrowser() && (baseURL = getEnv()) && (urlParams = getQueryParameter())

  return (
    canonical && canonical.indexOf('https') === 0 ? canonical + `${urlParams}` : 
    slug ? `https://www.westernunion.com${slug}` + `${urlParams}` : ``
  )
}

export default ({ title, pageContext, seo, isEvergageEnabled, isWubotEnabled, isWuPlus, darkTheme, isLisaChatEnabled }) => {
  let baseURL = ''
  isBrowser() && (baseURL = getEnv())
  const evergageValues = EvergageScript({
    url: process.env.EVERGAGE_URL
  })

  let metaTags = [
    { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' }
  ]

  let opengraphUrl = ``
  if (
    seo
  ) {
    opengraphUrl = seo.opengraphUrl && seo.opengraphUrl.indexOf('http') === 0 ? seo.opengraphUrl : `${baseURL}${seo.opengraphUrl.slice(0, -1)}.html`

    metaTags = metaTags.concat([
      { name: 'description', content: seo.metaDesc },
      { name: 'keywords', content: seo.metaKeywords },
      
      { property: 'og:author', content: seo.opengraphAuthor },
      { property: 'og:title', content: seo.opengraphTitle },
      { property: 'og:description', content: seo.opengraphDescription },
      { property: 'og:site_name', content: seo.opengraphSiteName },
      { property: 'og:image', content: seo.opengraphImage },
      { property: 'og:type', content: "website" },
      { property: 'og:url', content: opengraphUrl },
    ])
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: pageContext?.language,
        pageContext: pageContext,
        dir:  process.env.RTL_LANGUAGES.indexOf(`${pageContext?.language}`) !== -1 ? 'rtl' : undefined,
        langClass : 'lang-'+pageContext?.language,
        isWuPlus: isWuPlus,
        darkTheme: darkTheme
      }}
      title={ title }>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="canonical" href={getCanonicalUrl(seo?.canonical, pageContext?.slug)} />
      < title > { seo && seo.title && unescapeAsciiCharacters(seo.title)} </title>
      < meta name = "description"
      content = {
        seo && seo.metaDesc && unescapeAsciiCharacters(seo.metaDesc)}
      />
      <meta name="keywords" content={ seo?.metaKeywords } />
      <meta property="og:author" content={ seo?.opengraphAuthor } />
      < meta property = "og:title"
        content = {
          seo && seo.opengraphTitle && unescapeAsciiCharacters(seo.opengraphTitle)
        }
      />
      <meta property="og:description" content={ 
        seo && seo.opengraphDescription && unescapeAsciiCharacters(seo.opengraphDescription)
        } />
      <meta property="og:site_name" content={ seo?.opengraphSiteName } />
      <meta property="og:image" content={ seo?.opengraphImage } />
      <meta property="og:url" content={ seo?.opengraphUrl } />
      <meta property="og:type" content="website" />

      {isBrowser() && isEvergageEnabled === 'Yes' && <script async type="text/javascript" src={ evergageValues.src }></script>}
      {isBrowser() && isWubotEnabled === 'Yes' && <script async type="text/javascript" src={withAssetPrefix(`/scripts/vendors/openChat.js`)}></script>}
      {isBrowser() && isLisaChatEnabled === 'Yes' && <script async type="text/javascript" src={withAssetPrefix(`/scripts/vendors/lisa-ai-chat-widget-soks-v06.js`)}></script>}
      
            
    </Helmet>
  )
}