import { isBrowser } from "../utils/common.js";
const {
  isValidURL,
  getCurrentCountry,
  isCookieBucketingEnabled,
  getCookie,
} = require("../utils/common.js")


const EvergageScript = options => {
  const isProd = isBrowser() && window.sessionStorage.getItem('environmentName');
  const env = isProd === 'PROD'? 'prod': 'uat';
  try {
    if (!!options && !!options.url) {
      const senderCountry = getCurrentCountry()
      const country = senderCountry === "gb" ? "uk" : senderCountry
      let url = options.url.replace(
          new RegExp(/\${country}/, "g"),
          country
        ).replace(
          new RegExp(/\${env}/, "g"),
          env
        ),
        inHead = !!options.head,
        beforeLoad = !!options.beforeLoad
      let cookieOptin = getCookie("cookieOptIn")

      if (
        isValidURL(url) &&
        (cookieOptin === "" || !isCookieBucketingEnabled(options.cookieBucketEnabledCountries) || (isCookieBucketingEnabled(options.cookieBucketEnabledCountries) && cookieOptin !== null && cookieOptin.indexOf("CKTXNL") !== -1))
      ) {
        return {
          src: url,
          inHead: inHead,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
        }
      } else {
        return
      }
    } else {
      console.log(`Please verify the evergage url: ${options.url}`)
    }
  } catch (e) {
    console.log(`There is an error. Evergage cannot be loaded.`)
  }
}

export default EvergageScript;